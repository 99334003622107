<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        For the three molecules shown below, determine the <b>best</b> Lewis structure from the set
        provided. Note: formal charges on all atoms are purposefully omitted.
      </p>

      <v-divider />

      <p class="mt-5">a) <stemble-latex content="$\text{Methanol, CH}_3\text{OH}$" /></p>
      <p class="pl-8">
        <v-img style="width: 550px" src="@/assignments/assets/img/MeOH_Structures.png" />
      </p>

      <v-radio-group v-model="inputs.meoh" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-divider />

      <p class="mt-5">b) <stemble-latex content="$\text{Phosphorus trichloride, PCl}_3$" /></p>
      <p class="pl-8">
        <v-img style="width: 550px" src="@/assignments/assets/img/PCl3_Structures.png" />
      </p>

      <v-radio-group v-model="inputs.pcl3" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-divider />

      <p class="mt-5">c) <stemble-latex content="$\text{Methanimine, CNH}_3$" /></p>
      <p class="pl-8">
        <v-img style="width: 550px" src="@/assignments/assets/img/CH2NH_Structures.png" />
      </p>

      <v-radio-group v-model="inputs.ch2nh" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question210',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        meoh: null,
        pcl3: null,
        ch2nh: null,
      },
      options1: [
        {expression: '$\\text{Structure A}$', value: 'a'},
        {expression: '$\\text{Structure B}$', value: 'b'},
        {expression: '$\\text{Structure C}$', value: 'c'},
        {expression: '$\\text{Structure D}$', value: 'd'},
      ],
    };
  },
};
</script>
